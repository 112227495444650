import React, { useEffect, useState, useRef } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from './Card';
import playIcon from './assets/play.svg';
import pauseIcon from './assets/pause.svg';
import secretPlayIcon from './assets/secret.png';
import secretPauseIcon from './assets/secretPlay.png'

const CenteredCell = ({ activeCard }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef();
  const canvasRef = useRef();
  const animationRef = useRef();
  const audioContextRef = useRef();
  const analyserRef = useRef();
  const sliderTrackColor = activeCard && activeCard.audioUrl.includes('secret.opus') ? '#efe00b' : 'rgb(40, 44, 44)';
  const sliderActiveColor = activeCard && activeCard.audioUrl.includes('secret.opus') ? '#b90e76' : 'rgb(169, 169, 169)';
  
  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const CenteredCellStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  };
  
  const sliderContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    position: 'relative',
    left: '0',
    top: '20px',
    alignItems: 'flex-start',
    margin: '0 7.5vw 0 0',
  };
  
  const timeTextStyle = {
    margin: '0 10px',
  };

  const playPauseButtonStyle = {
    width: '70px',
    height: '70px',
    marginLeft: '45%',
    background: 'transparent',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  };

  const audioControlContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  };

  const volumeSliderStyle = {
    width: '8vw',
    height: '5px',
    background: `linear-gradient(to right, ${sliderActiveColor} ${volume * 100}%, ${sliderTrackColor} 0)`,
    appearance: 'none',
    marginRight: '12.5%',
    borderRadius: '5px',
  };

  const timeSliderContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  };

  const timeSliderStyle = {
    flex: '1',
    height: '5px',
    marginBottom: '0px',
    borderRadius: '5px',
    background: activeCard
  ? `linear-gradient(to right, ${sliderActiveColor} ${(currentTime / duration) * 100}%, ${sliderTrackColor} 0)`
  : sliderTrackColor,
    appearance: 'none',
    marginLeft: '10px',
    marginRight: '10px', 
  };

  const sliderThumbStyle = {
    appearance: 'none',
    width: '0',
    height: '0',
    cursor: 'pointer',
    border: '10px solid transparent',
  };

  const dashBoxStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${sliderActiveColor}`,
    borderRadius: '10px',
    width: '300px',
    minHeight: '150px',
    margin: '0 7.5vw 0 0',
  };

  const visualizerStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'gray',
    height: '100%',
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

const generateSliderStyles = (prefix) => `
  input[type='range']::${prefix}-slider-thumb {
    ${prefix}-appearance: none;
    cursor: pointer;
    ${Object.entries(sliderThumbStyle).map(([key, value]) => `${key}: ${value};`).join('\n')}
  }
  
  input[type='range']::${prefix}-slider-runnable-track {
    ${prefix}-appearance: none;
  }
`;

const globalStyles = `
  ${generateSliderStyles('-webkit')}
  ${generateSliderStyles('-moz')}
  ${generateSliderStyles('-ms')}
`;

useEffect(() => {
  if (audioRef.current) {
    audioRef.current.volume = volume;
  }
}, [activeCard, volume]);

  useEffect(() => {
    if (!audioContextRef.current) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
    }

    if (!analyserRef.current) {
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;
    }

    if (audioRef.current && activeCard) {
      if (audioContextRef.current.source) {
        audioContextRef.current.source.disconnect();
      }

      audioContextRef.current.source = audioContextRef.current.createMediaElementSource(audioRef.current);
      audioContextRef.current.source.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);

      const bufferLength = analyserRef.current.frequencyBinCount;
      const lastBarHeights = new Float32Array(bufferLength);

      let gradientShift = 0;

      const draw = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;

        const dataArray = new Uint8Array(bufferLength);
        analyserRef.current.getByteFrequencyData(dataArray);
        ctx.clearRect(0, 0, width, height);
        
        const barWidth = (width / bufferLength) * 8;
        let x = 0;

        gradientShift -= 0;
        if (gradientShift > width) gradientShift = 0;
        let gradient = ctx.createLinearGradient(gradientShift, 0, width + gradientShift, 0);
                  
        if (activeCard && activeCard.audioUrl.includes('secret.opus')) {
          gradient.addColorStop(0, '#b90e76');
          gradient.addColorStop(0.25, '#efe00b');
          gradient.addColorStop(0.5, '#b90e76');
          gradient.addColorStop(0.75, '#efe00b');
          gradient.addColorStop(1, '#b90e76');
        } else {
          gradient = 'rgb(169, 169, 169)';
        }
                          
        for (let i = 0; i < bufferLength; i += 4) {
          let barHeight = dataArray[i] / 2;
      
          if (lastBarHeights[i] > barHeight) {
            barHeight = lastBarHeights[i] - (lastBarHeights[i] * 0.1);
          }
      
          lastBarHeights[i] = barHeight;
      
          ctx.fillStyle = gradient;
          ctx.fillRect(x, height - barHeight, barWidth, barHeight);
          ctx.beginPath();
          ctx.arc(x + barWidth / 2, height - barHeight, barWidth / 2, Math.PI, 0, false);
          ctx.fill();
      
          x += barWidth + 1;
        }
      
        animationRef.current = requestAnimationFrame(draw);
      };
      
      audioRef.current.onplay = () => {
        audioContextRef.current.resume().then(() => {
          draw();
        });
      };

      audioRef.current.onpause = () => {
        cancelAnimationFrame(animationRef.current);
        const continueDecay = () => {
          const allBarsFlat = Array.from(lastBarHeights).every(height => height <= 0);
          if (!allBarsFlat) {
            draw();
          }
        };
        continueDecay();
      };
      
      return () => {
        cancelAnimationFrame(animationRef.current);
        if (audioContextRef.current?.source) {
          audioContextRef.current.source.disconnect();
          analyserRef.current.disconnect();
        }
      };
    }
  }, [activeCard]);

  useEffect(() => {
    if (audioRef.current && activeCard) {
      const playAudio = async () => {
        try {
          await audioRef.current.play();
          setIsPlaying(true);
        } catch (err) {
          console.error("Error trying to play the audio:", err);
          setIsPlaying(false);
        }
      };
      
      playAudio();
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
      }
    }
  }, [activeCard]);

  useEffect(() => {
    if (!activeCard) {
      setCurrentTime(0);
      setDuration(0);
      setIsPlaying(false);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [activeCard]);

  useEffect(() => {
    const audio = audioRef.current;
  
    if (audio) {
      const setAudioData = () => {
        setDuration(audio.duration);
        setCurrentTime(audio.currentTime);
      };
  
      const setAudioTime = () => setCurrentTime(audio.currentTime);
  
      audio.addEventListener('loadeddata', setAudioData);
      audio.addEventListener('timeupdate', setAudioTime);
      
      return () => {
        audio.removeEventListener('loadeddata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
      };
    }
  }, [audioRef, activeCard]);

  const handleTimeSliderChange = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onPlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const onPause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };
  
  return (
    <>
    <style>{globalStyles}</style>
      <div style={CenteredCellStyle}>
        <div style={sliderContainerStyle}>
          <div style={timeSliderContainerStyle}>
            <span style={timeTextStyle}>{formatTime(currentTime)}</span>
            <input
              type="range"
              min="0"
              max={duration || 1}
              value={currentTime}
              onChange={handleTimeSliderChange}
              style={timeSliderStyle}
              disabled={!activeCard}
            />
            <span style={timeTextStyle}>{formatTime(duration)}</span>
          </div>
          <div style={audioControlContainerStyle}>
          <button
            onClick={togglePlayPause}
            style={playPauseButtonStyle}
            disabled={!activeCard}
          >
          {activeCard && activeCard.audioUrl.includes('secret.opus') ? (
            <img src={isPlaying ? secretPlayIcon : secretPauseIcon} alt="Pause" style={{ width: '50px', height: '50px' }} />
          ) : (
            <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? "Pause" : "Play"} style={{ width: '50px', height: '50px' }} />
          )}
          </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              style={volumeSliderStyle}
            />
          </div>
        </div>
        <Droppable droppableId="centered-cell">
          {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div 
              ref={provided.innerRef} 
              {...provided.droppableProps}
              style={{
                ...dashBoxStyle, 
                backgroundColor: snapshot.isDraggingOver ? '#141414' : 'transparent',
              }}
            >
            {activeCard && (
              <Draggable draggableId="activeCard" index={0}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                    <div style={{ transform: 'scaleY(0.97) scaleX(0.97)'}}>
                      <Card data={activeCard} />
                    </div>
                  </div>
                )}
              </Draggable>
            )}
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                textAlign: 'center',
              }}
            >
              {!activeCard && "♪ Drop to play ♪"}
            </div>
            </div>
          </div>
          )}
        </Droppable>
        <div style={visualizerStyle}>
          <canvas ref={canvasRef} width="500" height="150"></canvas>
        </div>
        {activeCard && (
          <audio
            crossOrigin="anonymous"
            key={activeCard ? activeCard.id : undefined}
            ref={audioRef}
            src={activeCard ? activeCard.audioUrl : ''}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={() => {
              audioRef.current.pause();
              setIsPlaying(false);
            }}
            onLoadedMetadata={() => setDuration(audioRef.current.duration)}
            hidden
          />
        )}
    </div>
  </>
);
};

export default CenteredCell;
