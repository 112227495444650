import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Card from './Card';
import './DraggableVirtualGrid.css';

const DraggableVirtualGrid = React.memo(({ items }) => {
  const gridContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: 600 });
  const cardWidth = 220;
  const columnCount = Math.floor(dimensions.width / cardWidth);
  
  useEffect(() => {
    const handleResize = () => {
      if (gridContainerRef.current) {
        const { width, height } = gridContainerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  
  }, []);

  const CellRenderer = ({ columnIndex, rowIndex, style, data }) => {
    const linearIndex = rowIndex * columnCount + columnIndex;
    const item = data[linearIndex];
    
    return item ? (
      <Draggable key={item.id} draggableId={item.id} index={linearIndex}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...style,
              ...provided.draggableProps.style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card data={item} />
          </div>
        )}
      </Draggable>
    ) : null;
  };

  return (
    <Droppable droppableId="grid" mode="virtual"  direction="horizontal" renderClone={(provided, snapshot, rubric) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
      >
        <Card data={items[rubric.source.index]} />
      </div>
    )}>
      {(provided, snapshot) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '30px' }}>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="grid-container"
            style={{ width: dimensions.width, height: dimensions.height, overflow: 'hidden' }}
          >
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <Grid
                className="virtual-grid"
                columnCount={columnCount}
                columnWidth={cardWidth}
                height={600}
                rowCount={Math.ceil(items.length / columnCount)}
                rowHeight={125}
                width={Math.min(dimensions.width, columnCount * cardWidth + 20)}
                itemData={items}
                style={{ margin: '0 auto', position: 'relative' }}
              >
                {CellRenderer}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
});

export default DraggableVirtualGrid;