// Card.js
import React from 'react';

const Card = ({ data }) => {
  return (
    <div className="card">
      <div className="card-title">{data.title}</div>
      <img src={data.image} alt={data.title} className="card-image" loading="lazy" />
    </div>
  );
};

export default React.memo(Card);