import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from './Card';
import './TierList.css';

const tiers = ['S', 'A', 'B', 'C', 'D', 'E'];
const tierColors = {
  S: '#810001',
  A: '#814001',
  B: '#614800',
  C: '#616100',
  D: '#518001',
  E: '#018021',
};

const tierColorsFlip = {
  S: '#018021',
  A: '#518001',
  B: '#616100',
  C: '#614800',
  D: '#814001',
  E: '#810001',
};

const TierList = ({ cardsInTiers }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [keySequence, setKeySequence] = useState('');
  const [useFlipColors, setUseFlipColors] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      setKeySequence((prevSequence) => prevSequence + event.key);
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, []);

  useEffect(() => {
    if (keySequence.includes('flip')) {
      setUseFlipColors(!useFlipColors);
      setKeySequence('');
    }
  }, [keySequence]);

  const columnCount = Math.max(1, Math.floor(windowWidth / 200) - 1);

  return (
    <div className="tier-list">
      {tiers.map((tier) => {
        const cards = cardsInTiers[tier];
        const rows = Math.max(1, Math.ceil(cards.length / columnCount));
        const rowStyle = Math.max(0, Math.ceil(cards.length / columnCount));
        const top = rowStyle * 67.5;
        const isEmpty = cards.length === 0;
        const colors = useFlipColors ? tierColorsFlip : tierColors;
      
        return (
            <div key={tier} className="tier">
            {Array.from({ length: rows }, (_, rowIndex) => {
              const rowCards = cards.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount);
            
              return (
                <Droppable key={rowIndex} droppableId={`tier-${tier}-row-${rowIndex}`} direction="horizontal">
                  {(provided, snapshot) => (
                    <div className={`tier-row ${rowIndex > 0 ? 'appended-row' : ''}`} ref={provided.innerRef} {...provided.droppableProps} style={{ paddingLeft: isEmpty ? '0px' : '90px' }}>
                      <div className={`tier-label ${isEmpty ? 'tier-label-empty' : 'tier-label-fill'}`} style={{ top: `${top}px`, backgroundColor: colors[tier] }}>{tier}</div>
                      <div className="tier-cards" style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
                        {rowCards.map((card, index) => (
                          <Draggable key={card.id} draggableId={card.id} index={index + rowIndex * columnCount}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`card-container ${snapshot.isDragging ? 'dragging' : ''}`}
                                style={{ ...provided.draggableProps.style }}
                              >
                                <Card data={card} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {rowCards.length <= 1 && provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TierList;